import React, { useMemo } from "react";
import { isProduction } from "@helpers/isProduction.helper";
import { Helmet } from "react-helmet";
import { DynamicBlock } from "src/blocks/DynamicBlock.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import SocialMediaSharing from "src/blocks/components/SocialMediaSharing/SocialMediaSharing.component";
import useFormattedCultureDate from "@hooks/useFormattedCultureDate.hook";
import { AuthorBlock } from "../AuthorBlock/AuthorBlock.component";
import LatestNews from "./components/LatestNews/LatestNews.component";
import { NewsPageProps } from "./NewsPage.interface";
//News Schema markup
import { generateNewsSchemaMarkup } from "./Interfaces/NewsSchemaMarkup";
import S from "./NewsPage.module.scss";

const NewsPage = (props: NewsPageProps) => {
    const robotsIndexFollow = useMemo(() => {
        if (!isProduction()) {
            return "noindex, nofollow";
        }

        return `${props.props.allowIndexing === true ? "index" : "noindex"},
                ${props.props.allowFollowing === true ? "follow" : "nofollow"}`;
    }, [props.props?.allowFollowing, props.props?.allowIndexing]);


    //use dateCulture from Umbraco global settings
    const publishDate = useFormattedCultureDate(props?.props?.publishedDate);

    return (
        <main className={S.wrapper} id="main">
            <Helmet>
                <title>{props.props?.metaTitle || props.name}</title>
                <meta name="description" content={props.props?.metaDescription} />
                <meta name="robots" content={robotsIndexFollow} />
                {/* News Schema Markup */}
                <script type="application/ld+json">
                    {generateNewsSchemaMarkup(props)}
                </script>
            </Helmet>

            {/* Hero */}
            {props?.props?.hero?.[0]
                ? props.props.hero?.map((block, index) => <DynamicBlock key={index} {...block} />)
                : null}

            <DA_Container className={S.container} noVerticalPadding>
                <main className={S.main} id="main">
                    <div className={S.toolbar}>
                        {/** Author */}
                        {props?.props?.authorImage?.url || props?.props?.authorName ? (
                            <AuthorBlock
                                authorImageUrl={props?.props?.authorImage?.url}
                                authorImageName={props?.props?.authorName}
                                authorName={props?.props?.authorName}
                                authorTitle={props?.props?.authorTitle}
                                authorDate={publishDate}
                            />
                        ) : props.props.publishedDate ? (
                            <strong>{publishDate}</strong>
                        ) : null}

                        <div className={S.tags}>
                            {props.props.tags.map((tag, index) => (
                                <div className={S.tag} key={index}>
                                    {tag}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/** Title */}
                    <DA_Title h2 tag={DA_TitleTag.H2}>
                        {props.props.title}
                    </DA_Title>

                    {/** Text */}
                    <strong className={S.introductory}>{props.props.introductory}</strong>

                    {/* Blocks */}
                    <div className={S.content}>
                        {props?.props?.modules?.[0]
                            ? props.props.modules?.map((block, index) => (
                                <DynamicBlock key={index} {...block} compact />
                            ))
                            : null}
                    </div>
                    <SocialMediaSharing />
                </main>

                {/** Related News */}
                <aside className={S.aside}>
                    <LatestNews take={3} />
                </aside>
            </DA_Container>
        </main>
    );
};

export default NewsPage;
